/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl, Link } from 'gatsby-plugin-react-intl';

import DiscordBrand from './images/discord-brands.inline.svg';
import FacebookBrand from './images/facebook-brands.inline.svg';
import InstagramBrand from './images/instagram-brands.inline.svg';
import SteamBrand from './images/steam-brands.inline.svg';
import TwitterBrand from './images/twitter-brands.inline.svg';
import VkBrand from './images/vk-brands.inline.svg';
import YoutubeBrand from './images/youtube-brands.inline.svg';

import { GAME, BLOG_URL, BLOG_LANG } from '../../constants';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';

import * as s from './Footer.module.css';

const messages = defineMessages({
  instantSale: {
    id: 'footer.link.instantSale',
    defaultMessage: 'instant sale',
  },
  marketplace: {
    id: 'footer.link.marketplace',
    defaultMessage: 'marketplace',
  },
  investors: {
    id: 'footer.link.investors',
    defaultMessage: 'investors',
  },
  blog: {
    id: 'footer.link.blog',
    defaultMessage: 'blog',
  },
  partnership: {
    id: 'footer.link.partnership',
    defaultMessage: 'partnership',
  },
  sitemap: {
    id: 'footer.link.sitemap',
    defaultMessage: 'sitemap',
  },
  faq: {
    id: 'footer.link.faq',
    defaultMessage: 'FAQ',
  },
  termsOfService: {
    id: 'footer.link.termsOfService',
    defaultMessage: 'terms of Service',
  },
  privacy: {
    id: 'footer.link.privacy',
    defaultMessage: 'privacy',
  },
  faqInstant: {
    id: 'footer.link.faqInstant',
    defaultMessage: 'FAQ Instant',
  },
  faqMarket: {
    id: 'footer.link.faqMarket',
    defaultMessage: 'FAQ Market',
  },
  affiliateProgram: {
    id: 'footer.link.affiliateProgram',
    defaultMessage: 'Affiliate program',
  },
});

const LINK = {
  INSTANT: {
    id: 'instantSale',
    name: messages.instantSale,
    url: '/panel/deposit',
  },
  MARKET: {
    id: 'marketplace',
    name: messages.marketplace,
    url: '/market',
  },
  INVESTORS: { id: 'investors', name: messages.investors, url: '/spolka/' },
  PARTNERSHIP: {
    id: 'partnership',
    name: messages.partnership,
    url: 'mailto:partnerships@skinwallet.com?subject=Partnership',
  },
  SITEMAP: { id: 'sitemap', name: messages.sitemap, to: '/sitemap/' },
  FAQ_INSTANT: { id: 'faqInstant', name: messages.faqInstant, to: '/faq/' },
  FAQ_MARKET: {
    id: 'faqMarket',
    name: messages.faqMarket,
    url: '/market/faq',
    target: '_top',
  },
  TERMS_OF_SERVICE: {
    id: 'termsOfService',
    name: messages.termsOfService,
    to: '/terms-of-service/',
  },
  PRIVACY: { id: 'privacy', name: messages.privacy, to: '/privacy-policy/' },
  AFFILIATE_PROGRAM: {
    id: 'affiliateProgram',
    name: messages.affiliateProgram,
    url: 'https://skinwallet.tapfiliate.com/',
    target: '_blank',
  },
};

const SOCIAL_MEDIA = {
  FACEBOOK: {
    id: 'facebook',
    name: 'Skinwallet Facebook',
    url: 'https://www.facebook.com/Skinwalletcom',
    icon: <FacebookBrand />,
  },
  TWITTER: {
    id: 'twitter',
    name: 'Skinwallet Twitter',
    url: 'https://twitter.com/Skinwalletcom',
    icon: <TwitterBrand />,
  },
  STEAM: {
    id: 'steam',
    name: 'Skinwallet Steam',
    url: 'https://steamcommunity.com/groups/SkinwalletOfficial',
    icon: <SteamBrand />,
  },
  INSTAGRAM: {
    id: 'instagram',
    name: 'Skinwallet Instagram',
    url: 'https://www.instagram.com/skinwalletcom',
    icon: <InstagramBrand />,
  },
  YOUTUBE: {
    id: 'youtube',
    name: 'Skinwallet YouTube',
    url: 'https://www.youtube.com/channel/UCIsBEDaxRx1-15R-liq8sxA',
    icon: <YoutubeBrand />,
  },
  VK: {
    id: 'vk',
    name: 'Skinwallet VK',
    url: 'https://vk.com/skinwalletcom',
    icon: <VkBrand />,
  },
  DISCORD: {
    id: 'discord',
    name: 'Skinwallet Discord',
    url: 'https://discord.gg/SVEk8Ye',
    icon: <DiscordBrand />,
  },
};

const mainNav = [
  LINK.INSTANT,
  LINK.MARKET,
  LINK.AFFILIATE_PROGRAM,
  LINK.INVESTORS,
];
const extraNav = [
  LINK.PARTNERSHIP,
  LINK.SITEMAP,
  LINK.FAQ_INSTANT,
  LINK.FAQ_MARKET,
];
const legalNav = [LINK.TERMS_OF_SERVICE, LINK.PRIVACY];

const socials = [
  SOCIAL_MEDIA.STEAM,
  SOCIAL_MEDIA.FACEBOOK,
  SOCIAL_MEDIA.TWITTER,
  SOCIAL_MEDIA.YOUTUBE,
  SOCIAL_MEDIA.INSTAGRAM,
  SOCIAL_MEDIA.VK,
  SOCIAL_MEDIA.DISCORD,
];

const NavigationLink = ({ caption, url, to, target }) => {
  if (to) {
    return (
      <Link to={to} aria-label={caption}>
        {caption}
      </Link>
    );
  }

  if (url) {
    return (
      <a href={url} target={target} aria-label={caption}>
        {caption}
      </a>
    );
  }

  return null;
};

NavigationLink.propTypes = {
  caption: PropTypes.string.isRequired,
  url: PropTypes.string,
  to: PropTypes.string,
  target: PropTypes.string,
};

NavigationLink.defaultProps = {
  url: null,
  to: null,
  target: '_blank',
};

const Footer = ({ game }) => {
  const { locale, formatMessage } = useIntl();
  const date = new Date();
  const year = date.getFullYear();
  const blogLang = BLOG_LANG[game].find((lang) => lang === locale);
  const blogUrl = blogLang ? `/${blogLang}${BLOG_URL[game]}` : BLOG_URL[game];

  return (
    <footer id="footer" className={s.root}>
      <div className={s.steamCopyrightSection}>
        <p className={s.steamCopyright}>
          Powered by{' '}
          <a
            href="https://store.steampowered.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Steam
          </a>
          <br />
          Not affiliated with Valve Corp.
        </p>
      </div>
      <div id="footer-navigation" className={s.navigationSection}>
        <div className={s.navigation}>
          <nav className={s.mainNavigation}>
            <ul>
              {mainNav.map((nav) => (
                <li key={nav.id}>
                  <NavigationLink caption={formatMessage(nav.name)} {...nav} />
                </li>
              ))}
              <li>
                <a
                  href={blogUrl}
                  target="_blank"
                  aria-label={formatMessage(messages.blog)}
                >
                  {formatMessage(messages.blog)}
                </a>
              </li>
            </ul>
          </nav>
          <div>
            <nav className={s.legalNavigation}>
              <ul>
                {legalNav.map((nav) => (
                  <li key={nav.id}>
                    <NavigationLink
                      caption={formatMessage(nav.name)}
                      {...nav}
                    />
                  </li>
                ))}
              </ul>
            </nav>
            <nav className={s.extraNavigation}>
              <ul>
                {extraNav.map((nav) => (
                  <li key={nav.id}>
                    <NavigationLink
                      caption={formatMessage(nav.name)}
                      {...nav}
                    />
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className={s.socialMediaSection}>
        <ul className={s.socialMedia}>
          {socials.map((social) => (
            <li key={social.id}>
              <a
                href={social.url}
                aria-label={social.name}
                target="_blank"
                rel="noopener noreferrer"
              >
                {social.icon}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className={s.copyrightSection}>
        <p className={s.copyright}>{`© ${year} Skinwallet`}</p>
      </div>
      <div className={s.emptySection} />
      <div className={s.languageSwitcherSection}>
        <LanguageSwitcher />
      </div>
    </footer>
  );
};

Footer.propTypes = {
  game: PropTypes.oneOf(Object.keys(GAME)).isRequired,
};

export default Footer;
